import logoPNG from "./logo_datagrids.png";
import logoSVG from "./logo_datagrids.svg";
import workingPeople from "./working_people.png"
import avatarLudo from "./avatar_ludo.jpg"
import image_1 from "./image_1.jpg"
export default {
  logoPNG,
  logoSVG,
  workingPeople,
  avatarLudo,
  image_1
};
