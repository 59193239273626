import React from 'react';

const Square = ({ }) => {
    const [color, setColor] = React.useState('#0284c730');

    
    const handleMouseOver = () => {
      
    setColor('#0284c7'); // sky 600
    };

    const handleMouseOut = () => {
        
        const updateColor = () => {
            const newColor = '#0284c730' ;
            setColor(newColor);
          };
      
          let timer = setTimeout(updateColor, 3000);
      
          return () => {
            clearTimeout(timer);
          };
    };
    
    React.useEffect(() => {

    
        const updateColor = () => {
          const newColor = '#0284c760';
          setColor(newColor);
        };
    
        const randomInterval = Math.floor(Math.random() * (11000 - 1000 + 1)) + 1000;
        const timer = setInterval(updateColor, randomInterval);
    
        return () => {
          clearInterval(timer);
        };
      }, []);

    React.useEffect(() => {
        
    
        const updateColor = () => {
          const newColor = '#0284c730';
          setColor(newColor);
        };
    
        const randomInterval = Math.floor(Math.random() * (11000 - 1000 + 1)) + 1000;
        const timer = setInterval(updateColor, randomInterval);
    
        return () => {
          clearInterval(timer);
        };
      }, []);  

      const windowWidth = window.innerWidth;
      let squareSize;

      if (windowWidth > 768) {
        squareSize = 120;
      } else {
        squareSize = 90;
      }

      const styles = {
        width: squareSize,
        height: squareSize,
        backgroundColor: color,
        position: 'absolute'
        }
    return <div style={styles} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} />;
};




export default Square;
