import { Link } from "react-router-dom"

export default function constructionPage() {
    return (
      <div className="bg-white">
        <main>    
            <div className="relative isolate pt-14" >
            <svg
                className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                aria-hidden="true"
            >
                <defs>
                <pattern
                    id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                    width={200}
                    height={200}
                    x="50%"
                    y={-1}
                    patternUnits="userSpaceOnUse"
                >
                    <path d="M100 200V.5M.5 .5H200" fill="none" />
                </pattern>
                </defs>
                <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
                <path
                    d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                    strokeWidth={0}
                />
                </svg>
                <rect width="100%" height="100%" strokeWidth={0} fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" />
            </svg>
            <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-40">
                
                <div className="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
                <div className="mx-auto w-[22.875rem] max-w-full drop-shadow-xl"></div>




                      
                    <div className="max-w-lg">
                        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">This page is still under construction</h1>
                        <p className="mt-6 text-base leading-7 text-gray-600">
                        Sorry, the page you’re looking for is not ready for public yet.
                        </p>
                        <div className="mt-10">
                        <Link to="/" className="text-sm font-semibold leading-7 text-sky-600">
                            <span aria-hidden="true">&larr;</span> Back to home
                        </Link>
                        </div>
                    </div>



            </div>
          </div>
        </div>
    </main>
</div>
    )
  }
  