import NavBar from "./components/NavBar"
import Footer from "./components/footer";
import LandingPage from "./components/landingPage";
import About from "./components/about";
import Careers from "./components/careers";
import Contact from "./components/contact";
import Dataanalytics from "./components/dataanalytics";
import Machinelearningengineering from "./components/machinelearningengineering";
import Dataengineering from "./components/dataengineering";
import Service from "./components/service";
import Resources from "./components/resources";
import ErrorPage from "./components/error";
import { useRoutes } from "react-router-dom";
import ConstructionPage from "./components/underconstruction";


const App = () => {
  const routes = useRoutes([
    {path:"/", element: <LandingPage/>},
    {path:"/about", element: <ConstructionPage/>},
    {path:"/careers", element: <ConstructionPage/>},
    {path:"/contact", element: <Contact/>},
    {path:"/services", element: <Service/>},
    {path:"/dataanalytics", element: <Dataanalytics/>},
    {path:"/machinelearningengineering", element: <ConstructionPage />},
    {path:"/dataengineering", element: <Dataengineering />},
    {path:"/resources", element: <ConstructionPage />},
    {path:"*", element: <ErrorPage />},
  ])

  return (
    <div>
      <NavBar />

      {routes}
      <Footer/>
    </div>
      
  );
}

export default App;
