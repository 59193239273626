import assets from '../assets'
import MoreInfo from './moreInfo'
import {  Cog8ToothIcon, WrenchScrewdriverIcon, ChartPieIcon } from '@heroicons/react/24/outline'
const features = [
  {
    name: 'Data Engineering',
    description:
      'At Datagrids, we understand that data engineering is a complex process. Let us help you navigate this complexity and build a scalable data architecture that meets your business needs.',
    href: '/dataengineering',
    icon: WrenchScrewdriverIcon,
  },
  {
    name: 'Machine Learning Engineering',
    description:
      "We believe that data is only valuable if it can be transformed into actionable insights. That's why our machine learning engineering services are designed to help you make informed decisions that drive results.",
    href: '/machinelearningengineering',
    icon: Cog8ToothIcon,
  },
  {
    name: 'Data Analytics',
    description:
      'With our data analysis and visualization services, you can transform complex data sets into meaningful visualizations that facilitate decision-making. Our experts are here to help you unlock the full potential of your data and drive business growth.',
    href: '/dataanalytics',
    icon: ChartPieIcon,
  },
]


export default function Contact() {
  return (

    <div className="bg-white">
    <main>    
        <div className="relative isolate pt-14" >
          <svg
            className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                width={200}
                height={200}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse"
              >
                <path d="M100 200V.5M.5 .5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
              <path
                d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect width="100%" height="100%" strokeWidth={0} fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" />
          </svg>
          <div className="mx-auto max-w-7xl px-6 pt-24 sm:pt-32 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:pt-40">
            
            <div className="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
              <div className="mx-auto w-[22.875rem] max-w-full drop-shadow-xl"></div>

            {/* start content */}

              <div className="overflow-hidden py-24 sm:py-32">
                  <div className="mx-auto max-w-7xl md:px-6 lg:px-8">
                    <div className="grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-start">
                      <div className="px-6 lg:px-0 lg:pr-4 lg:pt-4">
                        <div className="mx-auto max-w-2xl lg:mx-0" style={{"animation": "fadeLeftMini 1s", "animation-fill-mode": "both"}}>
                          <p className="text-lg font-semibold leading-8 tracking-tight text-sky-600">Find the service you need</p>
                          <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Everything you need to design, develop and deploy your solution</h1>
                          <p className="mt-6 text-xl leading-8 text-gray-700">
                          As you embark on your company's data and AI journey, you realize that you need to have a 
                          strong foundation in place to make the most of your data. This is where Datagrids comes 
                          in. Our team of expert data engineers can help you build a solid data architecture and
                          pipelines that will support your business goals.
                          </p>
                        </div>

                          <div className="max-w-xl text-base leading-7 text-gray-700 lg:col-span-7" style={{"animation": "fadeLeftMini 1s", "animation-fill-mode": "both"}}>
                            <p> By investing in data engineering services from Datagrids, you can expect to see a number of benefits for your business. 
                            First and foremost, you'll have a more efficient data infrastructure that allows you to process and analyze data 
                            quickly and accurately. This can help you make better business decisions, identify trends and patterns, and improve 
                            your overall performance.
                            </p>

                            <p className="mt-8">
                            Overall, data engineering is an essential component of any data and AI journey. By working with Datagrids, 
                            you can ensure that your data infrastructure is strong, efficient, and scalable, setting your business up 
                            for success now and in the future.
                            </p>
                          </div>
                        </div>
  
                      <div className="sm:px-6 lg:px-0" style={{"animation": "fadeRightMini 1s", "animation-fill-mode": "both"}}>
                        <div className="relative isolate overflow-hidden bg-blue-900 px-6 pt-8 sm:mx-auto sm:max-w-2xl sm:rounded-3xl sm:pl-16 sm:pr-0 sm:pt-16 lg:mx-0 lg:max-w-none">
                          <div
                            className="absolute -inset-y-px -left-3 -z-10 w-full origin-bottom-left skew-x-[-30deg] bg-sky-600 opacity-20 ring-1 ring-inset ring-white"
                            aria-hidden="true"
                          />
                          <div className="mx-auto max-w-2xl sm:mx-0 sm:max-w-none">
                            <img
                              src={assets.image_1}
                              alt="Product screenshot"
                              width={2432}
                              height={1442}
                              className="-mb-12 w-[36rem] max-w-none rounded-tl-xl bg-gray-800 ring-1 ring-white/10"
                            />

                            
                          </div>
                        

                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>




                 {/* end content */}
              </div>
          </div>
        </div>


                {/* Feature section */}
                        <div className="mx-auto max-w-7xl px-6 lg:px-8" style={{"animation": "fadeLeftMini 1s", "animation-fill-mode": "both"}} >
                        <div className="mx-auto max-w-2xl lg:text-center">
                          </div>
                          {/* <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none"> */}
                          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
                            {features.map((feature) => (
                              <div key={feature.name} className="flex flex-col">
                                <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                                  <feature.icon className="h-5 w-5 flex-none text-sky-600" aria-hidden="true" />
                                  {feature.name}
                                </dt>
                                <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                                  <p className="flex-auto">{feature.description}</p>
                                  <p className="mt-6">
                                    <a href={feature.href} className="text-sm font-semibold leading-6 text-sky-600">
                                      Learn more <span aria-hidden="true">→</span>
                                    </a>
                                  </p>
                                </dd>
                              </div>
                            ))}
                          </dl>
                          {/* </div> */}
                        
                      </div>

      <MoreInfo title={"Unlock the power of your data."} 
      slogan={"Get in touch with one of our specialists to discover how our data engineering, data science, and data analytics solutions can guide your business forward."}
      />

    </main>
</div>


  )
}
