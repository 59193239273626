import { BanknotesIcon, BuildingOffice2Icon, EnvelopeIcon, ClipboardDocumentListIcon } from '@heroicons/react/24/outline'
import Square from './landingPage/Squares'
import Bar from './landingPage/Bar'

export default function Contact() {
  return (
    <div className="bg-white">
    <main>    
        <div className="relative isolate pt-14" >
        <svg
            className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
            aria-hidden="true"
        >
            <defs>
            <pattern
                id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                width={200}
                height={200}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse"
            >
                <path d="M100 200V.5M.5 .5H200" fill="none" />
            </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
            <path
                d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                strokeWidth={0}
            />
            </svg>
            <rect width="100%" height="100%" strokeWidth={0} fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" />
        </svg>
        <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-40">
            
            <div className="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
            <div className="mx-auto w-[22.875rem] max-w-full drop-shadow-xl"></div>


            <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-40" >
            <div className="mx-auto max-w-2xl lg:mx-0 lg:flex-auto" style={{"animation": "fadeLeftMini 1s", "animation-fill-mode": "both"}}>
              <div className="flex">
                {/* <div className="relative flex items-center gap-x-4 rounded-full px-4 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                  <span className="font-semibold text-blue-900">We’re hiring</span>
                  <span className="h-4 w-px bg-gray-900/10" aria-hidden="true" />
                  <Link to="/careers" className="flex items-center gap-x-1">
                    <span className="absolute inset-0" aria-hidden="true" />
                    See open positions
                    <ChevronRightIcon className="-mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                  </Link>
                </div> */}
              </div>
              <h1 className="mt-10 max-w-lg text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl" >
                Contact one of our professionals 
              </h1>
              <p className="mt-6 text-lg leading-8 text-gray-600">
              Do you want to know more about one of our services or do you want to get in 
            </p>
            <dl className="mt-10 space-y-4 text-base leading-7 text-gray-600">
              <div className="flex gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">Address</span>
                  <BuildingOffice2Icon className="h-7 w-6 text-gray-400" aria-hidden="true" />
                </dt>
                <dd>
                  Laarderhoogtweg 18, 
                  <br />
                  1101 EA Amsterdam
                </dd>
              </div>
              <div className="flex gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">Email</span>
                  <EnvelopeIcon className="h-7 w-6 text-gray-400" aria-hidden="true" />
                </dt>
                <div>
                    ludo
                </div>
                <div>
                    @
                </div>
                <div>
                    datagrids.nl
                </div>
              </div>
              <div className="flex gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">bank adress</span>
                  <BanknotesIcon className="h-7 w-6 text-gray-400" aria-hidden="true" />
                </dt>
                <dd>
                  NL 37 RABO 0370-7495-88
                </dd>
              </div>
              <div className="flex gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">Chaimber of commerce number</span>
                  <ClipboardDocumentListIcon className="h-7 w-6 text-gray-400" aria-hidden="true" />
                </dt>
                <dd>
                  <a className="hover:text-gray-900" href="mailto:hello@example.com">
                    kvk: 83184414
                  </a>
                </dd>
              </div>
            </dl>
            </div>

            
            <div className="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
              <div className="mx-auto w-[22.875rem] max-w-full drop-shadow-xl"></div>
              {/* <svg viewBox="0 0 366 729" role="img" className="mx-auto w-[22.875rem] max-w-full drop-shadow-xl"> */}
              {/* <div className="" > */}
              <div className="flex flex-col space-y-24 md:space-y-36" style={{"animation": "fadeRightMini 1s", "animation-fill-mode": "both"}}>
                <div className="flex space-x-24 ml-6 md:space-x-36 md:ml-12">
                    <div ><Square/></div>
                    <div><Square/></div>
                    <div><Square/></div>
                </div>
                <div className="flex space-x-24 ml-6 md:space-x-36 md:ml-12">
                    <div><Square/></div>
                    <div><Square/></div>
                    <div><Square/></div>
                </div>
                
                <div>
                    <div ><Bar/></div>
                </div>
            </div>
            </div>
            </div>




            </div>
          </div>
        </div>
    </main>
</div>
  )
}
