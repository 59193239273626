import { ShoppingBagIcon, BuildingOfficeIcon, ArrowTrendingUpIcon } from '@heroicons/react/20/solid'
import MoreInfo from './moreInfo'
import assets from '../assets'
const features = [
  {
    name: 'Retail',
    description:
    "For example, imagine you're a retailer with a massive amount of customer data stored across various systems. With data engineering from Datagrids, you could create a centralized data warehouse that consolidates all of your data in one place. This would allow you to analyze customer behavior across multiple touchpoints, such as online and in-store purchases, and develop targeted marketing campaigns based on that data.",
    icon: ShoppingBagIcon,
  },
  {
    name: 'Healthcare',
    description:
      "Or perhaps you're a healthcare provider looking to improve patient outcomes. By building a data pipeline that integrates patient data from various sources - such as electronic health records, wearable devices, and genomic data - you can gain a more comprehensive understanding of each patient's health and tailor treatments accordingly.",
    icon: BuildingOfficeIcon,
  },
  {
    name: 'Finance',
    description:
    "Data engineering can also be beneficial for industries such as finance, where accurate and timely data is essential for making investment decisions. With a well-designed data architecture and pipelines in place, you can quickly process market data, analyze trends, and make informed trades.",
    icon: ArrowTrendingUpIcon,
  },
]


export default function machinelearningengineering() {
    return (



      <div className="bg-white">
    <main>    
        <div className="relative isolate pt-14" >
          <svg
            className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                width={200}
                height={200}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse"
              >
                <path d="M100 200V.5M.5 .5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
              <path
                d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect width="100%" height="100%" strokeWidth={0} fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" />
          </svg>
          <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-40">
            
            <div className="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
              <div className="mx-auto w-[22.875rem] max-w-full drop-shadow-xl"></div>

            {/* start content */}

              <div className="overflow-hidden py-24 sm:py-32">
                  <div className="mx-auto max-w-7xl md:px-6 lg:px-8">
                    <div className="grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-start">
                      <div className="px-6 lg:px-0 lg:pr-4 lg:pt-4">
                        <div className="mx-auto max-w-2xl lg:mx-0" style={{"animation": "fadeLeftMini 1s", "animation-fill-mode": "both"}}>
                          <p className="text-lg font-semibold leading-8 tracking-tight text-sky-600">Find the service you need</p>
                          <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Our Data Engineering service</h1>
                          <p className="mt-6 text-xl leading-8 text-gray-700">
                          As you embark on your company's data and AI journey, you realize that you need to have a 
                          strong foundation in place to make the most of your data. This is where Datagrids comes 
                          in. Our team of expert data engineers can help you build a solid data architecture and
                          pipelines that will support your business goals.
                          </p>
                        </div>

                          <div className="max-w-xl text-base leading-7 text-gray-700 lg:col-span-7" style={{"animation": "fadeLeftMini 1s", "animation-fill-mode": "both"}}>
                            <p> By investing in data engineering services from Datagrids, you can expect to see a number of benefits for your business. 
                            First and foremost, you'll have a more efficient data infrastructure that allows you to process and analyze data 
                            quickly and accurately. This can help you make better business decisions, identify trends and patterns, and improve 
                            your overall performance.
                            </p>

                            <p className="mt-8">
                            Overall, data engineering is an essential component of any data and AI journey. By working with Datagrids, 
                            you can ensure that your data infrastructure is strong, efficient, and scalable, setting your business up 
                            for success now and in the future.
                            </p>
                            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">No available servers? No problem.</h2>
                            <p className="mt-6">
                              At Datagrids, we understand that not all businesses have the resources to set up 
                              and maintain their data infrastructure. That's why we offer cloud-based 
                              solutions for data engineering. With our expertise in cloud technologies, we can set up and 
                              manage cloud environments that are specifically tailored to your business needs. This 
                              means you don't have to worry about investing in expensive hardware or hiring additional 
                              IT staff to manage your data infrastructure.
                            </p>
                          </div>
                        </div>
  
                      <div className="sm:px-6 lg:px-0" style={{"animation": "fadeRightMini 1s", "animation-fill-mode": "both"}}>
                        <div className="relative isolate overflow-hidden bg-blue-900 px-6 pt-8 sm:mx-auto sm:max-w-2xl sm:rounded-3xl sm:pl-16 sm:pr-0 sm:pt-16 lg:mx-0 lg:max-w-none">
                          <div
                            className="absolute -inset-y-px -left-3 -z-10 w-full origin-bottom-left skew-x-[-30deg] bg-sky-600 opacity-20 ring-1 ring-inset ring-white"
                            aria-hidden="true"
                          />
                          <div className="mx-auto max-w-2xl sm:mx-0 sm:max-w-none">
                            <img
                              src={assets.image_1}
                              alt="Product screenshot"
                              width={2432}
                              height={1442}
                              className="-mb-12 w-[36rem] max-w-none rounded-tl-xl bg-gray-800 ring-1 ring-white/10"
                            />

                            
                          </div>
                          
                          <div
                            className="pointer-events-none absolute inset-0 ring-1 ring-inset ring-black/10 sm:rounded-3xl"
                            aria-hidden="true"
                          />
                        </div>
                        <figure className="border-l border-sky-600 pl-8 mt-24"  style={{"animation": "fadeRightMini 1s", "animation-fill-mode": "both"}}>
                                  <blockquote className="text-xl font-semibold leading-8 tracking-tight text-gray-900">
                                    <p>
                                    “Data engineering may sound like a complex concept, but at its core, it's all about building the 
                                      infrastructure that allows your data to flow smoothly from source to destination. 
                                      This includes designing data storage solutions, creating data pipelines, and ensuring 
                                      that your data is clean and consistent.”

                                    </p>
                                  </blockquote>
                            
                                </figure>
                      </div>
                      
                    </div>
                  </div>
                </div>




                 {/* end content */}
              </div>
          </div>
        </div>


                {/* Feature section */}
                <div className="bg-blue-900 py-24 sm:py-12">
                        <div className="mx-auto max-w-7xl px-6 lg:px-8" style={{"animation": "fadeLeftMini 1s", "animation-fill-mode": "both"}} >
                        <div className="mx-auto max-w-2xl lg:text-center">
                            <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
                            Some examples
                            </p>
                          </div>
                          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
                            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
                              {features.map((feature) => (
                                <div key={feature.name} className="flex flex-col">
                                  <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-white">
                                    <feature.icon className="h-5 w-5 flex-none text-sky-400" aria-hidden="true" />
                                    {feature.name}
                                  </dt>
                                  <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-300">
                                    <p className="flex-auto">{feature.description}</p>
                                  </dd>
                                </div>
                              ))}
                            </dl>
                          </div>
                        </div>
                      </div>

      <MoreInfo title={"Unlock the power of your data."} 
      slogan={"Get in touch with one of our specialists to discover how our data engineering, data science, and data analytics solutions can guide your business forward."}
      />

    </main>
</div>
    )
  }
