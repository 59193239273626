
import assets from "../assets"
import { Link } from "react-router-dom"

export default function moreInfo({title, slogan}) {
    return (
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8" style={{"animation": "fadeRightMini 1s", "animation-fill-mode": "both"}}>
          <div className="bg-white">
            <div className="mx-auto max-w-7xl py-24 sm:px-6 sm:py-32 lg:px-8">


              <div className="relative isolate bg-blue-900 px-6 py-24 text-center shadow-2xl sm:rounded-3xl sm:px-16 z-10">
                <img
                  className="absolute object-cover h-0 w-0 rounded-full z-20  lg:-left-10 lg:-top-10 lg:h-40 lg:w-40"
                  src={assets.avatarLudo}
                  alt="avatar Ludo van den Nieuwelaar"
                />

                <h2 className="mx-auto max-w-2xl text-3xl font-bold tracking-tight text-white sm:text-4xl">
                  {title}
                </h2>
                <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">
                 {slogan}
                {/* Get in touch with one of our specialists to discover how our data engineering, data science, and data analytics solutions can guide your business forward. */}
                </p>
                <div className="mt-10 flex items-center justify-center gap-x-6">
                  <Link
                    to="/contact"
                    className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                  >
                   Get in touch
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div> 
        )
}        