/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import MoreInfo from './moreInfo';
import assets from '../assets';
import Bar from './landingPage/Bar'
import Square from './landingPage/Squares'
import { Link } from 'react-router-dom'
import {  Cog8ToothIcon, WrenchScrewdriverIcon, ChartPieIcon } from '@heroicons/react/24/outline'
import { CloudArrowUpIcon, LockClosedIcon, CurrencyDollarIcon} from '@heroicons/react/20/solid'

const features = [
  {
    name: 'Limitless scaling.',
    description:
      'With cloud computing, you can easily scale up or down your computing power as needed. This means you can handle sudden spikes in traffic or add more resources without having to invest in expensive hardware.',
    icon: CloudArrowUpIcon,
  },
  {
    name: 'Enhanced security.',
    description: 'By using cloud services, your data is kept safe and secure with advanced security measures that are constantly updated to stay ahead of potential threats.',
    icon: LockClosedIcon,
  },
  {
    name: 'Cost-effectiveness.',
    description: 'By eliminating the need for expensive hardware, software, and maintenance costs, you can significantly reduce your IT expenses. With pay-as-you-go pricing, you only pay for the resources you use, allowing you to scale up or down as needed without any long-term commitments.',
    icon: CurrencyDollarIcon,
  },
]
const secondaryFeatures = [
  {
    name: 'Data Engineering',
    description:
      'At Datagrids, we understand that data engineering is a complex process. Let us help you navigate this complexity and build a scalable data architecture that meets your business needs.',
    href: '/dataengineering',
    icon: WrenchScrewdriverIcon,
  },
  {
    name: 'Machine Learning Engineering',
    description:
      "We believe that data is only valuable if it can be transformed into actionable insights. That's why our machine learning engineering services are designed to help you make informed decisions that drive results.",
    href: '/machinelearningengineering',
    icon: Cog8ToothIcon,
  },
  {
    name: 'Data Analytics',
    description:
      'With our data analysis and visualization services, you can transform complex data sets into meaningful visualizations that facilitate decision-making. Our experts are here to help you unlock the full potential of your data and drive business growth.',
    href: '/dataanalytics',
    icon: ChartPieIcon,
  },
]


export default function LandingPage() {
  
  return (
    <div className="bg-white">
      <main>
        {/* Hero section */}
        <div className="relative isolate pt-14" >
          <svg
            className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                width={200}
                height={200}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse"
              >
                <path d="M100 200V.5M.5 .5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
              <path
                d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect width="100%" height="100%" strokeWidth={0} fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" />
          </svg>
          <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-40">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">
              <div className="flex">
                {/* <div className="relative flex items-center gap-x-4 rounded-full px-4 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                  <span className="font-semibold text-blue-900">We’re hiring</span>
                  <span className="h-4 w-px bg-gray-900/10" aria-hidden="true" />
                  <Link to="/careers" className="flex items-center gap-x-1">
                    <span className="absolute inset-0" aria-hidden="true" />
                    See open positions
                    <ChevronRightIcon className="-mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                  </Link>
                </div> */}
              </div>
              <h1 className="mt-10 max-w-lg text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl" style={{"animation": "fadeLeftMini 1s", "animation-fill-mode": "both"}}>
                We're supporting companies with their data and AI journey.
              </h1>
              <p className="mt-6 text-lg leading-8 text-gray-600" style={{"animation": "fadeLeftMini 1s", "animation-fill-mode": "both"}}>
              We are Datagrids. We understand that data and Artificial Intelligence are key to staying competitive in today's business world. Let us help you navigate your data and Artificial Intelligence journey with our expert services.
              </p>
              <div className="mt-10 flex items-center gap-x-6" style={{"animation": "fadeLeftMini 1s", "animation-fill-mode": "both"}}>
                <Link
                  to="/services"
                  className="rounded-md bg-blue-900 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-800"
                >
                  Our services
                </Link>
                <Link to="/contact" className="text-sm font-semibold leading-6 text-gray-900">
                  Contact us <span aria-hidden="true">→</span>
                </Link>
              </div>
            </div>
            <div className="mt-16 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
              <div className="mx-auto w-[22.875rem] max-w-full "></div>
              {/* <svg viewBox="0 0 366 729" role="img" className="mx-auto w-[22.875rem] max-w-full drop-shadow-xl"> */}
              {/* <div className="" > */}
              <div className="flex flex-col space-y-24 md:space-y-36" style={{"animation": "fadeRightMini 1s", "animation-fill-mode": "both"}}>
                <div className="flex space-x-24 ml-6 md:space-x-36 md:ml-12">
                    <div ><Square/></div>
                    <div><Square/></div>
                    <div><Square/></div>
                </div>
                <div className="flex space-x-24 ml-6 md:space-x-36 md:ml-12">
                    <div><Square/></div>
                    <div><Square/></div>
                    <div><Square/></div>
                </div>
                
                <div>
                    <div ><Bar/></div>
                </div>
            </div>
            </div>
          </div>
        </div>

        {/* Logo cloud */}
        {/* <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-12 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 sm:gap-y-14 lg:mx-0 lg:max-w-none lg:grid-cols-5">
            <img
              className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
              src="https://tailwindui.com/img/logos/158x48/transistor-logo-gray-400.svg"
              alt="Transistor"
              width={158}
              height={48}
            />
            <img
              className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
              src="https://tailwindui.com/img/logos/158x48/reform-logo-gray-400.svg"
              alt="Reform"
              width={158}
              height={48}
            />
            <img
              className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
              src="https://tailwindui.com/img/logos/158x48/tuple-logo-gray-400.svg"
              alt="Tuple"
              width={158}
              height={48}
            />
            <img
              className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
              src="https://tailwindui.com/img/logos/158x48/savvycal-logo-gray-400.svg"
              alt="SavvyCal"
              width={158}
              height={48}
            />
            <img
              className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
              src="https://tailwindui.com/img/logos/158x48/statamic-logo-gray-400.svg"
              alt="Statamic"
              width={158}
              height={48}
            />
          </div>
        </div> */}

        {/* Feature section */}
        <div className="bg-blue-900 py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8"  >
            <div className="mx-auto max-w-2xl lg:text-center"  style={{"animation": "fadeLeftMini 1s", "animation-fill-mode": "both"}}>
              <h2 className="text-base font-semibold leading-7 text-sky-400">Deploy faster</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
              Everything you need to design, develop and deploy your solution
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-300">
              Our data engineering, data science, and data analytics services are designed to help you deploy your solution faster 
              and stay ahead of the curve in a rapidly changing business landscape.
              </p>
            </div>
            <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none"  style={{"animation": "fadeRightMini 1s", "animation-fill-mode": "both"}}>
              <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
                {secondaryFeatures.map((feature) => (
                  <div key={feature.name} className="flex flex-col">
                    <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-white">
                      <feature.icon className="h-5 w-5 flex-none text-sky-400" aria-hidden="true" />
                      {feature.name}
                    </dt>
                    <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-300">
                      <p className="flex-auto">{feature.description}</p>
                      <p className="mt-6">
                        <Link to={feature.href} className="text-sm font-semibold leading-6 text-sky-400">
                          Learn more <span aria-hidden="true">→</span>
                        </Link>
                      </p>
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>


        <div className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8" >
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2" >
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg"  style={{"animation": "fadeLeftMini 1s", "animation-fill-mode": "both"}}>
              <h2 className="text-base font-semibold leading-7 text-sky-600">Drive progress</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Accelerated by cloud computing </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
              At Datagrids, we understand that the world of cloud computing can be daunting, which is why we are here to 
              be your guide and partner in navigating it. By choosing cloud computing solutions, you can have peace of 
              mind knowing that your data is secure, your computing power is scalable, and your costs are manageable. 
              Our team of experts will work with you to assess your business needs, identify the right cloud solution for your company, and implement it seamlessly. 
              Let us help you leverage the power of the cloud and take your business to the next level. With Datagrids, you have a trusted partner to help you every step of the way.
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon className="absolute left-1 top-1 h-5 w-5 text-sky-600" aria-hidden="true" />
                      {feature.name}
                    </dt>{' '}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <img
            src="https://swimburger.net/media/ppnn3pcl/azure.png"
            alt="Product screenshot"
            className="w-[48rem] max-w-16 sm:w-[57rem] md:-ml-4 lg:-ml-0 mt-44"
            width={2432}
            height={1442}
            style={{"animation": "fadeRightMini 1s", "animation-fill-mode": "both"}}
          />
        </div>
      </div>
    </div>


        {/* Feature section */}
        <div className="bg-white py-24 sm:py-24">
      <div className="mx-auto max-w-7xl px-6 lg:px-8" style={{"animation": "fadeRightMini 1s", "animation-fill-mode": "both"}}>
        <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="">
            <img class="object-fill w-max mt-12" src={assets.workingPeople} alt="Working People" ></img>
          </div>

          <div>
            <div className="text-base leading-7 text-gray-700 lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-sky-600">Unlock potential</p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Leverage your data and AI.
              </h1>
              <div className="max-w-xl">
                <p className="mt-6">
                Datagrids believes that your company can achieve great things 
                through the power of technology. But we understand that it can be overwhelming and complex to navigate 
                this journey on your own. That's why we're here to guide you every step of the way, providing expert advice 
                and tailored solutions to help you achieve your goals. 
                </p>
                <p className="mt-8">
                With Datagrids, you can rest assured that you have a 
                trusted partner by your side, empowering you to make informed decisions and unlocking the full potential of 
                your data and AI. Let us help you turn your data into a competitive advantage and take your business to the 
                next level.
                </p>
                {/* <p className="mt-8">
                  Et vitae blandit facilisi magna lacus commodo. Vitae sapien duis odio id et. Id blandit molestie
                  auctor fermentum dignissim. Lacus diam tincidunt ac cursus in vel. Mauris varius vulputate et ultrices
                  hac adipiscing egestas. Iaculis convallis ac tempor et ut. Ac lorem vel integer orci.
                </p> */}
              </div>
            </div>
            <div className="mt-10 flex">
              <Link to="/about" className="text-base font-semibold leading-7 text-sky-600">
                Learn more about our company <span aria-hidden="true">&rarr;</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>







    {/* companies section */}

    {/* <div className="bg-blue-900 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-12 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 sm:gap-y-14 lg:mx-0 lg:max-w-none lg:grid-cols-5">
          <img
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src="https://tailwindui.com/img/logos/158x48/transistor-logo-white.svg"
            alt="Transistor"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src="https://tailwindui.com/img/logos/158x48/reform-logo-white.svg"
            alt="Reform"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src="https://tailwindui.com/img/logos/158x48/tuple-logo-white.svg"
            alt="Tuple"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
            src="https://tailwindui.com/img/logos/158x48/savvycal-logo-white.svg"
            alt="SavvyCal"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
            src="https://tailwindui.com/img/logos/158x48/statamic-logo-white.svg"
            alt="Statamic"
            width={158}
            height={48}
          />
        </div>
        <div className="mt-16 flex justify-center">
          <p className="relative rounded-full bg-white px-4 py-1.5 text-sm leading-6 text-gray-800">
            <span className="hidden md:inline">Whichever tool you use or want to use to better your bussines. </span>
            <a href="#" className="font-semibold text-black">
              <span className="absolute inset-0" aria-hidden="true" /> Read our {' '}
              <span aria-hidden="true">&rarr;</span>
            </a>
          </p>
        </div>
      </div>
    </div>
 */}

        {/* Newsletter section */}
        <MoreInfo title={"Unlock your data with Data Engineering."} 
      slogan={"Get in touch with one of our specialists to discover how our data engineering services can guide your business forward."}
      />



        {/* Testimonials section */}

      </main>


    </div>
  )
}
