import { ShoppingBagIcon, BuildingOfficeIcon, ArrowTrendingUpIcon } from '@heroicons/react/20/solid'
import MoreInfo from './moreInfo'
import assets from '../assets'
const features = [
  {
    name: 'More control over processes:',
    description:
    "By analyzing data from your business processes, you can gain a deeper understanding of what's working and what's not. This allows you to make more informed decisions about how to allocate resources, optimize workflows, and drive efficiency.",
    icon: ShoppingBagIcon,
  },
  {
    name: 'More insights into relevant metrics:',
    description:
      "Data analytics allows you to identify which metrics are most relevant to your business and track them over time. This helps you focus on the key performance indicators that matter most and make data-driven decisions that drive growth.",
    icon: BuildingOfficeIcon,
  },
  {
    name: 'Better customer insights:',
    description:
    "By analyzing customer data, you can gain valuable insights into their behavior, preferences, and needs. This allows you to create more personalized marketing campaigns, improve customer service, and drive customer engagement.",
    icon: ArrowTrendingUpIcon,
  },
]


export default function dataanalytics() {
    return (



      <div className="bg-white">
    <main>    
        <div className="relative isolate pt-14" >
          <svg
            className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                width={200}
                height={200}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse"
              >
                <path d="M100 200V.5M.5 .5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
              <path
                d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect width="100%" height="100%" strokeWidth={0} fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" />
          </svg>
          <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-40">
            
            <div className="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
              <div className="mx-auto w-[22.875rem] max-w-full drop-shadow-xl"></div>

            {/* start content */}

              <div className="overflow-hidden py-24 sm:py-32">
                  <div className="mx-auto max-w-7xl md:px-6 lg:px-8">
                    <div className="grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-start">
                      <div className="px-6 lg:px-0 lg:pr-4 lg:pt-4">
                        <div className="mx-auto max-w-2xl lg:mx-0" style={{"animation": "fadeLeftMini 1s", "animation-fill-mode": "both"}}>
                          <p className="text-lg font-semibold leading-8 tracking-tight text-sky-600">Find the service you need</p>
                          <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Our Data Analytics service</h1>
                          <p className="mt-6 text-xl leading-8 text-gray-700">
                          Are you tired of feeling like you're flying blind when it comes to making business decisions? 
                          Are you struggling to make sense of the massive amounts of data your company is generating every day? 
                          At Datagrids, we specialize in data analytics services that help businesses like yours gain valuable
                          insights from their historical data. By aggregating your data and creating the right visualizations, 
                          we can help you understand key metrics that drive your business.
                          </p>
                        </div>
                        

                        <div className="max-w-xl text-base leading-7 text-gray-700 lg:col-span-7" style={{"animation": "fadeLeftMini 1s", "animation-fill-mode": "both"}}>
                            <p> By investing in data analytics services from Datagrids, you can expect to see a number of benefits for your business:
                            </p>
                            <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                            {features.map((feature) => (
                              <div key={feature.name} className="relative pl-9">
                                <dt className="inline font-semibold text-gray-900">
                                  <feature.icon className="absolute left-1 top-1 h-5 w-5 text-sky-600" aria-hidden="true" />
                                  {feature.name}
                                </dt>{' '}
                                <dd className="inline">{feature.description}</dd>
                              </div>
                            ))}
                          </dl>
                            <p className="mt-8">
                            Overall, data analytics is an essential component of any data and AI journey. By working with Datagrids, 
                            you can ensure that your data visualization is robust autonomous and efficient, setting your business up 
                            for success now and in the future.
                            </p>
                            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">Data analytics for every scale</h2>
                            <p className="mt-6">
                            Data analytics is not only for big businesses. Even small businesses can benefit from 
                            gaining more control over their processes and understanding which metrics are most 
                            relevant to their business. For example, a local coffee shop could use data analytics 
                            to analyze customer traffic and understand which times of day are busiest. 
                            Armed with this knowledge, the coffee shop could adjust staffing schedules and 
                            improve customer service during peak hours.
                            </p>
                          </div>
                        </div>
  
                      <div className="sm:px-6 lg:px-0" style={{"animation": "fadeRightMini 1s", "animation-fill-mode": "both"}}>
                        <div className="relative isolate overflow-hidden bg-blue-900 px-6 pt-8 sm:mx-auto sm:max-w-2xl sm:rounded-3xl sm:pl-16 sm:pr-0 sm:pt-16 lg:mx-0 lg:max-w-none">
                          <div
                            className="absolute -inset-y-px -left-3 -z-10 w-full origin-bottom-left skew-x-[-30deg] bg-sky-600 opacity-20 ring-1 ring-inset ring-white"
                            aria-hidden="true"
                          />
                          <div className="mx-auto max-w-2xl sm:mx-0 sm:max-w-none">
                            <img
                              src={assets.image_1}
                              alt="Product screenshot"
                              width={2432}
                              height={1442}
                              className="-mb-12 w-[36rem] max-w-none rounded-tl-xl bg-gray-800 ring-1 ring-white/10"
                            />

                            
                          </div>
                          
                          <div
                            className="pointer-events-none absolute inset-0 ring-1 ring-inset ring-black/10 sm:rounded-3xl"
                            aria-hidden="true"
                          />
                        </div>
                        <figure className="border-l border-sky-600 pl-8 mt-24"  style={{"animation": "fadeRightMini 1s", "animation-fill-mode": "both"}}>
                                  <blockquote className="text-xl font-semibold leading-8 tracking-tight text-gray-900">
                                    <p>
                                    “Data analytics is the process of analyzing historical data to 
                                    gain insights and identify patterns that can enhance decision-making. 
                                    By aggregating data and serving it in the right visualizations, we can 
                                    help you understand key metrics that drive your business. With 
                                    our autonomous data analytics solutions, you'll have the insights 
                                    you need at your fingertips, without having to spend hours poring over spreadsheets.”

                                    </p>
                                  </blockquote>
                            
                                </figure>
                      </div>
                      
                    </div>
                  </div>
                </div>




                 {/* end content */}
              </div>
          </div>
        </div>


                {/* Feature section */}
                <div className="bg-blue-900 py-24 sm:py-12">
                        <div className="mx-auto max-w-7xl px-6 lg:px-8" style={{"animation": "fadeLeftMini 1s", "animation-fill-mode": "both"}} >
                        <div className="mx-auto max-w-2xl lg:text-center">
                            <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
                            Some examples
                            </p>
                          </div>
                          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
                            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
                              {features.map((feature) => (
                                <div key={feature.name} className="flex flex-col">
                                  <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-white">
                                    <feature.icon className="h-5 w-5 flex-none text-sky-400" aria-hidden="true" />
                                    {feature.name}
                                  </dt>
                                  <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-300">
                                    <p className="flex-auto">{feature.description}</p>
                                  </dd>
                                </div>
                              ))}
                            </dl>
                          </div>
                        </div>
                      </div>

      <MoreInfo title={"Unlock the power of your data."} 
      slogan={"Get in touch with one of our specialists to discover how our data engineering, data science, and data analytics solutions can guide your business forward."}
      />

    </main>
</div>
    )
  }
