import React from 'react';

const Bar = ({ top, left }) => {
    const [color, setColor] = React.useState('#1e3a8a70');

    const handleMouseOver = () => {
    setColor('#1e3a8a'); // blue 900
    };

    const handleMouseOut = () => {

    const updateColor = () => {
        const newColor = '#1e3a8a70' ;
        setColor(newColor);
      };
  
      let timer = setTimeout(updateColor, 3000);
  
      return () => {
        clearTimeout(timer);
      };
    };
    
    const windowWidth = window.innerWidth;
    let barSize;
    if (windowWidth > 768) {
      barSize = 500;
    } else {
      barSize = 330;
    }
    
    const styles = {
    width: barSize,
    height: '10px',
    backgroundColor: color,
    position: 'absolute',

    
    };

    return <div style={styles} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} />;
};
    
export default Bar;
